<template>
<b-container fluid>
  <bo-page-title />

  <b-card-header v-if="isList">
    <div class="d-flex justify-content-between">
      <b-button-group>
        <b-button size="sm" @click="setActiveMenu('')" :variant="active_filter == '' ? 'info' : 'outline-info'">All ({{total_all}})</b-button>
        <b-button size="sm" @click="setActiveMenu('operational')" :variant="active_filter == 'operational' ? 'info' : 'outline-info'">Operational ({{total_op}})</b-button>
        <b-button size="sm" @click="setActiveMenu('non-operational')" :variant="active_filter == 'non-operational' ? 'info' : 'outline-info'">Non Operational ({{total_non_op}})</b-button>
      </b-button-group>
      <b-button variant="success" @click="modalExpense()">
        <i class="fas fa-plus-circle mr-2"></i>
        Add Expense
      </b-button>
    </div>
  </b-card-header>

  <b-card v-if="isList" no-body>
    <b-card-header>
      <b-row>
        <b-col md="3">
          <date-picker value-type="format" format="YYYY-MM-DD" range prefix-class="crm" v-model="dateFilter" @input="doFilterDate" v-b-tooltip.hover="'Filter by Date'" />
        </b-col>
        <b-col lg="5">
        </b-col>
        <b-col lg="4">
          <SearchInput :value.sync="filter.search" @search="doFilter" />
        </b-col>
      </b-row>
    </b-card-header>
    <b-card-body class="p-0">
      <b-table
        :fields="fields"
        :items="dataList||[]"
        :primary-key="idKey"
        :busy="!dataList"
        responsive
        show-empty
        striped
      >
        <template #empty>
          <div class="text-center">
            <b-img class="mb-2" width="90" fluid src="/assets/images/no-data.png" />
            <h4 align="center"><span v-if="Object.keys($route.query).length">No search results found</span><span v-else>No {{ pageTitle }} listed yet</span></h4>
          </div>
        </template>
        <template #table-busy>
          <div class="text-center text-danger my-2">
            <b-spinner class="align-middle"></b-spinner>
            <strong>Loading...</strong>
          </div>
        </template>
        <template #cell(number)="v">
          {{(data.per_page*(data.current_page-1))+v.index+1}}
        </template>
        <template #cell(tp_note)="data">
          <div>{{ data.value }}</div>
          <b-badge :variant="expenseType(data.item.tp_type).color">{{ expenseType(data.item.tp_type).word }}</b-badge>
        </template>
        <template #cell(tp_amount)="data">
          {{ formatRupiah(data.value) }}
        </template>
        <template #cell(tp_date)="data">
          {{ data.value | moment('LL') }}
        </template>
        <template #cell(mt_target)="data">
          {{ formatRupiah(data.value) }}
        </template>
        <template #cell(mt_achieve)="data">
          <span class="text-danger">{{ formatRupiah(data.value) }}</span>
        </template>
        <template
          #cell(action)="data"
        >
          <a
            v-if="moduleRole('edit')"
            v-b-tooltip.hover
            title="Edit"
            class="icon_action"
            @click="modalExpense(data.item, true)"
            style="cursor:pointer;"
          >
            <i class="ti-marker-alt"></i>
          </a>

          <span v-if="!(moduleRole('delete') || moduleRole('changestatus') || moduleRole('edit'))">-</span>
        </template>
      </b-table>
    </b-card-body>
    <b-card-footer>
      <template v-if="(dataList||[]).length&&data.total>10">
        <div class="d-flex align-items-center justify-content-between">
          <div class="d-flex align-items-center">
            <label for="perPage">Show</label>
              <b-form-select
                size="sm"
                id="perPage"
                class="w-auto mx-50"
                v-model="perPage"
                :options="Config.dataShownOptions"
              />
            <label for="perPage">items per page</label>
          </div>
          <b-pagination
            class="mb-0"
            v-model="pageNo"
            :per-page="data.per_page"
            :total-rows="data.total"
          />
        </div>
      </template>
    </b-card-footer>
  </b-card>

  <template v-else>
    <!-- <Form :row.sync="row" :Config="Config" :mrValidation="mrValidation"/> -->
  </template>
  <b-modal
      id="addExpense"
      :title="row.edit? 'Edit Expense' : 'Add Expense'"
      centered
      no-close-on-backdrop
    >
      <validation-observer v-slot="{ handleSubmit }" ref="VFormExpense">
        <b-form @submit.prevent="handleSubmit(addExpense())">
          <template v-if="row.edit">
            <b-row>
              <b-col md=6>
                <b-form-group label="Date" label-for="expenseType">
                  <span>{{ row.tp_date | moment('LL') }}</span>
                </b-form-group>
              </b-col>
            </b-row>
          </template>
          <b-form-group label-for="expenseType">
            <template #label>Expense Type <strong class="text-danger">*</strong></template>
            <v-select id="expenseType" v-model="row.tp_type" :options="expenseOption" :reduce="v => v.value" label="label" />
            <VValidate name="Expense Type" :rules="mrValidation.tp_type" v-model="row.tp_type" />
          </b-form-group>
          <b-form-group label-for="expName">
            <template #label>Item <strong class="text-danger">*</strong></template>
            <b-form-textarea id="expName" v-model="row.tp_note" rows="3" />
            <VValidate name="Item" :rules="mrValidation.tp_note" v-model="row.tp_note" />
          </b-form-group>
          <b-form-group label-for="amount">
            <template #label>Amount <strong class="text-danger">*</strong></template>
            <b-input-group prepend="Rp.">
              <money id="amount" v-model="row.tp_amount" v-bind="maskCurrency" placeholder="cth: 600.00" class="form-control"></money>
            </b-input-group>
            <VValidate name="Amount" :rules="mrValidation.tp_amount+'|min_value:10000'" v-model="row.tp_amount" />
          </b-form-group>
          <template v-if="!row.edit">
            <b-form-group label-for="date">
            <template #label>Date <strong class="text-danger">*</strong></template>
              <date-picker v-model="row.tp_date" value-type="format" format="YYYY-MM-DD" id="proProjectDate" prefix-class="crm" :disabled-date="notAfterToday" />
              <VValidate name="Date" :rules="mrValidation.tp_date" v-model="row.tp_date" />
            </b-form-group>
          </template>
        </b-form>
      </validation-observer>
      <template #modal-footer>
        <b-button size="md" variant="transparent" @click="closeModal('addExpense')">Cancel</b-button>
        <b-button size="md" variant="info" @click="addExpense()">{{row.edit ? 'Update Expense' : 'Submit Expense'}}</b-button>
      </template>
    </b-modal>
</b-container>
</template>
<script>
import GlobalVue from '@/libs/Global.vue'
import Gen from '@/libs/Gen'

export default {
  extends: GlobalVue,
  data() {
    return {
      data: [],
      idKey:'mt_id',
      mrValidation: {},
      active_filter: '',
      showImage: false,
      detailImg: '',
      typeFilter: [
        { label: 'All Type', value: '' },
        { label: 'Year', value: 'Y' },
        { label: 'Month', value: 'M' },
      ],
      fields: [
        {
          key: 'number', label: '#', 
        },
        {
          key: 'tp_note', label: 'Item',
        },
        {
          key: 'tp_amount', label: 'Amount', 
        },
        {
          key: 'tp_date', label: 'Date', 
        },
        {
          key: 'action', 
        },
      ],
      mrMenuRel:[],
      monthList: ['Januari', 'Februari', 'Maret', 'April', 'Mei', 'Juni', 'Juli', 'Agustus', 'September', 'Oktober', 'November', 'Desember'],
      expenseOption: [
        { label: 'Operational', value: 'operational' },
        { label: 'Non Operational', value: 'non-operational' }
      ],
      total_all: 0,
      total_op: 0,
      total_non_op: 0
    }
  },
  mounted(){
    this.apiGet()
    this.filter.type = this.$route.query.type || ''
    const a = []
    a[0] = this.$route.query['start-date'] || null
    a[1] = this.$route.query['end-date'] || null

    this.$set(this, 'dateFilter', a)
  },
  methods: {
    notAfterToday(date){
      return date > new Date()
    },
    doFilterDate(newVal){
      this.$set(this.filter, 'start-date', newVal[0] || '')
      this.$set(this.filter, 'end-date', newVal[1] || '')
      this.doFilter()
    },
    setActiveMenu(status){
      this.active_filter = status

      this.$set(this.filter, 'type', status)
      this.doFilter()
    },
    expenseType(val) {
      return val === 'operational' ? { color: 'info', word: 'Operational' } : { color: 'warning', word: 'Non-Operational' }
    },
    closeModal(name, reset = true){
      this.$bvModal.hide(name)

      if(reset) this.$set(this, 'row', {})
    },
    setLightBox(src){
      this.detailImg = src
      this.showImage = true
    },
    modalExpense(data = {}, edit = false){
      this.row = Object.assign({}, data)
      this.row.edit = edit

      this.$bvModal.show('addExpense')
    },
    addExpense(){
      this.$refs.VFormExpense.validate().then(success => {
        if(!success) return 

        this.$swal({
          title: this.row.edit ? 'Update Expense...' : 'Submit Expense...',
          showConfirmButton: false,
          allowOutsideClick: false,
          didOpen: () => {
            this.$swal.showLoading()
          }
        })

        Gen.apiRest(
          '/do/' + this.modulePage,
          { data: {type: this.row.edit ? 'edit-expense' : 'add-expense', ...this.row} },
          'POST'
        ).then(res => {
          if(res){
              this.$swal({
                title: res.data.message,
                text: '',
                timer: 1500,
                icon: 'success',
              }).then(() => {
                this.apiGet()
                this.$bvModal.hide('addExpense')
              })
          }
        }).catch(() => {
          this.apiGet()
          this.$bvModal.hide('addExpense')
        })
      })
    },
    doDelete(id){

      this.$swal({
        title: 'Are you sure delete this Expense?',
        showConfirmButton: true,
        showCancelButton: true,
        confirmCancelText: 'Cancel',
        confirmButtonText: 'Yes, Sure',
        icon: 'question',
      }).then(result => {
        if(result.value){
          Gen.apiRest(
            '/do/' + this.modulePage,
            { data: {type: 'delete-expense', id} },
            'POST'
          ).then(res => {
            if(res){
                this.$swal({
                  title: res.data.message,
                  timer: 1500,
                  icon: 'success',
                }).then(() => {
                  this.apiGet()
                })
            }
          }).catch(() => {
              this.apiGet()
          })

        }
      })

    },
  },
  watch:{
    $route(){
      this.apiGet()
    },
    'filter'(){
      const a = []
      a[0] = this.filter['start-date'] || null
      a[1] = this.filter['end-date'] || null
      this.$set(this, 'dateFilter', a)
    }
  }
}
</script>